<template>
  <el-card shadow="never" style="padding-bottom: 150px">
    <el-form :model="formData" label-width="200px">
      <el-row>
        <el-form-item label="渠道：">
          <el-select v-model="formData.boxSource" filterable class="form-item">
            <el-option v-for="item in recyclableBoxSourceList" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="渠道商：">
          <el-select v-model="formData.sourceProvider" filterable class="form-item">
            <el-option v-for="item in sourceProviders" :key="item.supplierCode" :label="item.supplierName" :value="item.supplierCode"></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="包材状态：">
          <el-select v-model="formData.boxStatus" filterable class="form-item">
            <el-option label="可用" value="10"></el-option>
            <el-option label="待维修" value="30"></el-option>
            <el-option label="待报废" value="50"></el-option>
            <el-option label="停用" value="100"></el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <div v-if="formData.boxStatus=='30'||formData.boxStatus=='50'">
        <el-row>
          <el-form-item label="不良备注：">
            <el-input v-model="formData.ngRemark" type="textarea" :rows="2" class="form-item"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="不良照片：">
            <PhotographComponent @setImgIds="setImgIds" :img-cnt="1" ref="imageUpload"/>
          </el-form-item>
        </el-row>
      </div>
      <el-row>
        <el-form-item label="包材条码：">
          <el-input ref="boxSn" v-model.trim="formData.boxSn" @change="boxSnChange" @keyup.enter.native="toNext('boxSn')" placeholder="请输入或扫包材条码" class="form-item" clearable></el-input>
          <span style="padding-left: 20px" class="tip" v-if="packMaterialCode">包材编码：{{ packMaterialCode }}</span>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="包材条码2：">
          <el-input ref="boxSn2" v-model.trim="formData.boxSn2" @keyup.enter.native="submit" placeholder="请输入或扫包材条码2" class="form-item" clearable></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :span="5" style="text-align: right">
          <el-button type="primary" @click="submit" :loading="loading">提交入库</el-button>
        </el-col>
        <el-col :span="19">
          <MessageComponent ref="commitMessage"/>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</template>

<script>
import MessageComponent from "@/components/MessageComponent";
import PhotographComponent from "@/components/PhotographComponent";

export default {
  name: "RecyclableBoxInStore",
  components: {PhotographComponent, MessageComponent},
  data() {
    return {
      formData: {
        boxSn: '',
        boxSn2: '',
        boxSource: '',
        sourceProvider: '',
        boxStatus: '',
        ngRemark: '',
        ngImageId: ''
      },
      sourceProviders: [],
      packMaterialCode: '',
      loading: false
    }
  },
  computed: {
    recyclableBoxSourceList() {
      return this.$store.getters.getDictList('recyclableBoxSource') || [];
    },
  },
  created() {
    this.$axios.get('recyclableBox/queryAllSupplier').then(response => {
      this.loading = false;
      let res = response.data;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.sourceProviders = res.data || []
    }, error => {
      this.loading = false;
      return this.$message.error('获取渠道商失败，' + error.message);
    })
  },
  methods: {
    toNext(current) {
      console.log('ddd', current)
      if (current == 'boxSn') {
        this.$refs.boxSn2.select();
        this.$refs.boxSn2.focus()
      }
    },
    clearData() {
      this.formData.boxSn = ''
      this.formData.boxSn2 = ''
      this.formData.ngRemark = ''
      this.packMaterialCode = '';
      if (this.$refs.imageUpload) {
        this.$refs.imageUpload.onClear();
      }
    },
    boxSnChange() {
      this.packMaterialCode = ''
      if (!this.formData.boxSn) {
        return;
      }
      this.formData.boxSn = this.formData.boxSn.toUpperCase();
      if (this.formData.boxSn.length != 23 || !this.formData.boxSn.startsWith("BOX")) {
        return this.$message.error("包材条码不正确");
      }

      this.$axios.post('recyclableBox/getPackMaterialCode', this.formData.boxSn).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }
        this.packMaterialCode = res.data || ''
      }, error => {
        this.loading = false;
        return this.$message.error('获取包材编码失败，' + error.message);
      })
    },
    submit() {
      this.$refs.commitMessage.clear();
      if (this.formData.boxSn.length != 23 || !this.formData.boxSn.startsWith("BOX")) {
        return this.$refs.commitMessage.showError("包材条码不正确");
      }
      if (this.formData.boxSn !== this.formData.boxSn2) {
        return this.$refs.commitMessage.showError("包材条码和包材条码2不一致");
      }
      if (!this.packMaterialCode) {
        return this.$refs.commitMessage.showError("未找到对应的包材编码，请核对。如未维护请在【包材管理->包材编码】里添加包材编码");
      }
      if (!this.formData.boxSource) {
        return this.$refs.commitMessage.showError("包材渠道不能为空。");
      }
      if (!this.formData.sourceProvider) {
        return this.$refs.commitMessage.showError("渠道商不能为空。");
      }
      if (!this.formData.boxStatus) {
        return this.$refs.commitMessage.showError("包材状态不能为空。");
      }
      if (this.formData.boxStatus != '10' && this.formData.boxStatus != '100') {
        if (!this.formData.ngRemark) {
          return this.$refs.commitMessage.showError("不良备注不能为空。");
        }
        if (!this.formData.ngImageId) {
          return this.$refs.commitMessage.showError("不良照片不能为空。");
        }
      }

      this.loading = true
      this.$axios.post('recyclableBox/inStore', this.formData).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.commitMessage.showError(res.message);
        }
        this.$refs.commitMessage.showSuccess('提交成功');
        this.clearData();
        this.$refs.boxSn.focus()
      }, error => {
        this.loading = false;
        return this.$refs.commitMessage.showError('提交失败，' + error.message);
      })
    },
    setImgIds(idList) {
      let arr = idList || [];
      this.formData.ngImageId = arr.length > 0 ? arr[0] : ''
    },
  }
}
</script>

<style scoped>
.form-item {
  width: 320px;
}
</style>